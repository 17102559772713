<template>
  <v-card flat class="xw-full xpb-[60px]" min-height="80vh">
    <v-card-title class="xflex xflex-row xjustify-between xgap-2 xitems-center">
      <app-search
        :api="`api/sites/search`"
        item-text="name"
        item-value="id"
        class="md:xmax-w-[300px] xmax-w-full"
        @selected="onSelect"
        @cleared="fetch"
      ></app-search>
      <btn-tooltip
        v-if="$can.and('manage_office')"
        color="primary"
        tip="Add new office site"
        @click="openModal(true, null)"
      >
        <v-icon left>mdi-plus</v-icon> Add Office
      </btn-tooltip>
    </v-card-title>
    <v-card-text>
      <app-table
        :loading="loading"
        :headings="headers"
        :items="items"
        empty-text="No office sites found."
      >
        <template v-slot:name="{ item }">
          <span>{{ item.name | ucwords }}</span>
        </template>
        <template v-slot:region="{ item }">
          <span>{{ item.region ? item.region.name : "" }}</span>
        </template>
        <template v-slot:created_at="{ item }">
          <span>{{ item.created_at | format("LL") }}</span>
        </template>
        <template v-slot:creator="{ item }">
          <avatar
            size="35"
            class="xfont-bold"
            :user="item.creator"
            with-name
          ></avatar>
        </template>
        <template v-slot:admin="{ item }">
          <avatar
            v-if="item.manager"
            size="35"
            class="xfont-bold"
            :user="item.manager"
            with-name
          ></avatar>
          <span v-else></span>
        </template>
        <template v-slot:actions="{ item }">
          <icon-list
            icon="mdi-dots-horizontal"
            v-if="$can.and('manage_office')"
          >
            <v-list dense>
              <v-list-item @click="openModal(true, item)">
                <v-list-item-title class="xtext-[#2E2E2E]">
                  <v-icon color="#2E2E2E" left>
                    mdi-circle-edit-outline
                  </v-icon>
                  Edit Office
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleDelete(item)">
                <v-list-item-title class="xtext-[#2E2E2E]">
                  <v-icon color="#2E2E2E" left> mdi-trash-can-outline </v-icon>
                  Delete Office
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </icon-list>
        </template>
      </app-table>
    </v-card-text>
    <v-card-actions
      v-if="next_page_url"
      class="xflex-col xflex xjustify-center xitems-center xw-full xh-[60px] xabsolute xbottom-0"
    >
      <btn-tooltip
        :loading="loading_more"
        color="primary"
        @click="fetchMore"
        tip="Load more..."
      >
        Load more
      </btn-tooltip>
    </v-card-actions>

    <SiteModal
      v-model="modal"
      :item="activeItem"
      @saved="fetch"
      @updated="fetch"
    ></SiteModal>
    <!-- <pre>{{ items }}</pre> -->
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SiteModal from "../components/SiteModal.vue";
export default {
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      modal: false,
      activeItem: null,
      loading: false,
      loading_more: false,
      next_page_url: null,
      items: [],
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Region", value: "region", sortable: true },
        { text: "Created By", value: "creator", sortable: true },
        { text: "Admin", value: "admin", sortable: true },
        { text: "Created At", value: "created_at", sortable: false },
        { text: "Projects", value: "projects_count", sortable: true },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    onSelect(val) {
      this.items = [val];
      this.next_page_url = null;
    },
    ...mapMutations("workspace", ["delete_site"]),
    ...mapActions("workspace", ["fetchWorkspaceStatistics"]),
    fetch() {
      this.loading = true;
      this.$axios
        .get(`api/workspaces/${this.user.team_id}/sites`)
        .then(({ data }) => {
          this.items = data.data;
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading = false));
    },
    fetchMore() {
      this.loading_more = true;
      this.$axios
        .get(this.next_page_url)
        .then(({ data }) => {
          this.items.push(...data.data);
          this.next_page_url = data.next_page_url;
        })
        .finally(() => (this.loading_more = false));
    },
    openModal(open, item) {
      this.activeItem = item;
      this.modal = open;
    },
    handleDelete(item) {
      this.appConfirmation(
        "Are you sure you want to delete this office site?<br/>This cannot be undone!",
        () => {
          this.$axios.delete(`api/sites/${item.id}`).then(({ data }) => {
            let index = this.items.findIndex((i) => i.id == data.id);
            if (~index) this.items.splice(index, 1);
            this.appToast("Site successfully deleted.", "success");
            this.delete_site(data.id);
            this.fetchWorkspaceStatistics(this.user.team_id);
          });
        }
      );
    },
  },
  components: { SiteModal },
};
</script>

<style lang="scss" scoped></style>
